:focus {
    outline: none;
}

.booking-form form input,
::placeholder,
input,
textarea,
select {
    font-family: "Roboto", sans-serif;
    color: #777777;
}

.preloader {
    display: flex;
}

.offcanvas-wrapper .offcanvas-widget {
    overflow-x: auto;
}

header .nav-container.breakpoint-on .nav-menu .menu-items ul li.menu-item-has-children>a::after {
    content: "\f107";
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    display: block;
    position: absolute;
    right: 0;
    height: 45px;
    width: 45px;
    top: 0;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    z-index: 2;
    background: transparent;
    text-align: center;
    line-height: 45px;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
}

.booking-form form .inputs-filed .nice-select select {
    margin-left: 0;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-appearance: none;
}

.input-group.input-group-two .nice-select,
.booking-form form .inputs-filed .nice-select {
    padding: 0;
    border: none;
}

.booking-form form .nice-select,
.booking-form form input,
.booking-form form select {
    background-color: transparent;
    width: 100%;
    height: 50px;
    font-size: 14px;
    border: none;
    border-bottom: 2px solid #bead8e;
    line-height: 50px;
    color: #777777;
}

.input-group.input-group-two .nice-select::before {
    left: auto;
    right: 30px;
}

.input-group.input-group-two .nice-select select {
    margin-left: 0;
    padding: 0 50px 0 30px;
    -webkit-appearance: none;
    font-size: 14px;
}

.nice-select::before {
    content: '\f063';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
    position: absolute;
    left: 30px;
    top: 50%;
    color: #bead8e;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__navigation {
    background: transparent;
    width: 10px;
    height: 10px;
    letter-spacing: normal;
    border: 0.45rem solid transparent;
}

.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next {
    border-left-color: #ccc !important;
}

.react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous {
    border-right-color: #ccc !important;
}

.latest-news .latest-post-box .post-desc .post-meta {
    font-size: 14px;
}

.latest-news .latest-post-box .post-desc .post-meta {
    margin-bottom: 20px;
    display: flex;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.latest-news .latest-post-box .post-desc h4 {
    margin-bottom: 15px;
}

.latest-news .latest-post-box .post-desc {
    padding: 25px;
}

.post-box .post-desc .post-footer .author img {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.news-details-box .entry-content .cat+.cat,
.post-box .post-desc .cat+.cat {
    margin-left: 15px;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-img {
    width: 80px;
    height: 80px;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.news-details-box .entry-footer .post-nav .next-post::before {
    content: '';
    background-image: url('assets/img/blog-details/icon.png');
    width: 40px;
    height: 40px;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
}

.counter-section .counter-box.counter-box-two h4 {
    display: inline-block;
}

.counter-section .counter-box.counter-box-two h4+.prefix {
    color: #222;
    letter-spacing: -1px;
    font-family: "Old Standard TT", serif;
    font-size: 60px;
    font-weight: 400;
    line-height: 1.2;
}

@media (max-width:767px) {
    .news-details-box .entry-footer .post-nav .next-post::before {
        display: none;
    }
}

.main-logo img,
.sticky-logo img {


    width: 100px;
    height: auto;

}

@media screen and (max-width: 767px) {
    .sticky-logo img {


        width: 50px;
        height: auto;

    }
}

.modal-content {
    max-width: 500px;
    margin: auto;
}

.banner-bg {
    background: url("./assets/img/main_img.jpg");
}

.facility-ico img {
    width: 90px;
    height: auto;
}

.modal-dialog {
    max-width: fit-content;
    max-width: -moz-fit-content;
    min-width: 80%;
    margin-inline: auto;
}

.modal-dialog .video-player-iframe {
    height: 700px
}

@media screen and (max-width: 1200px) {
    .modal-dialog .video-player-iframe {
        height: 600px
    }
}

@media screen and (max-width: 1000px) {
    .modal-dialog .video-player-iframe {
        height: 500px
    }
}

@media screen and (max-width: 991px) {
    .modal-dialog .video-player-iframe {
        height: 400px
    }
}

@media screen and (max-width: 767px) {
    .modal-dialog .video-player-iframe {
        height: 300px
    }
}

@media screen and (min-width: 2000px) {
    .modal-dialog {
        min-width: 1700px
    }

    .modal-dialog .video-player-iframe {
        height: 900px
    }
}

.floating-socials {
    position: fixed;
    bottom: 1.5rem;
    left: 1.5rem;
    background: rgba(0, 0, 0, .2);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    min-height: 32px;
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    z-index: 10;
    color: white;

}

.floating-socials a {
    color: white;
}

@media screen and (max-width: 767px) {
    .floating-socials {
        padding: .25rem;
        gap: 0.5rem;
        min-height: 24px;
    }

    .floating-socials svg {
        width: 16px;
        height: auto;
    }
}

.subtitle-main {
    color: white;
    margin-bottom: 3rem;
    font-size: 48px;
    line-height: 1;


}

@media screen and (max-width: 991px) {
    .subtitle-main {
        font-size: 36px
    }
}

@media screen and (max-width: 767px) {
    .subtitle-main {
        font-size: 28px
    }
}

.img-modal-footer {
    width: 1000px;
}

.modal-header {
    border-bottom: 0;
}

.modal-footer {
    border-top: 0
}

@media screen and (max-width: 767px) {

    .modal-dialog {
        margin-inline: 1rem;
    }
}

#video-modal {
    min-width: unset !important;
}

#video-modal video {
    width: 400px;
    height: auto;
}

.site-logo {
    margin-right: auto;

}

@media screen and (max-width: 991px) {
    .site-logo {
        margin: auto
    }
}

.booking-form-inner {
    position: relative;

}

.booking-form-inner .inputs-filed {
    z-index: 2;
}

.booking-form-inner::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("./assets/img/header-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 0;
    opacity: .6;
}

.lh-1 {
    line-height: 1.1;
}